import { AuthType } from 'types/common';

export const INVALID_PHONE_NUMBER = 'Invalid phone number.';
export const INVALID_LK_NUMBER = 'Only the whitelisted LK number are allowed in the system.';
export const INVALID_OTP = 'Invalid code, Please try again.';

export const authPagesTextMap = {
  [AuthType.SIGN_IN]: {
    title: 'Sign in',
    subTitle: `Don't have an account yet?`,
    linkText: 'Create Account',
    infoText: 'Enter your contact number to sign in',
    buttonText: 'Verify Number',
  },
  [AuthType.SIGN_UP]: {
    title: 'Create your account',
    subTitle: 'Already have an account?',
    linkText: 'Log in',
    infoText: 'Enter your contact number to get started',
    buttonText: 'Verify Number',
  },
};

export const publicRoutes = ["auth","share","public","terms","about","privacy", "landing"];