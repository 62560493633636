import { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { whoAmIAction } from 'modules/auth/slices/actions';
import OverlayLoader from 'components/loader/OverlayLoader';

import AuthRoutes from 'components/routes/AuthTree';
import BaseTree from 'components/routes/BaseTree';
import NotFound from 'modules/auth/pages/404/NotFound';
import SettingsTree from './components/routes/SettingsTree';
import Terms from './modules/auth/pages/terms/Terms';
import About from './modules/auth/pages/about/About';

import './App.css';
import 'assets/styles/variables.css';
import 'assets/fonts/fonts.css';
import VisitLogin from './VisitLogin';
import HomePage from './components/HomePage';
import LetsMeetRoutes from 'components/routes/LetsMeet';
import ActivitiesRoutes from 'components/routes/ActivityTree';
import { requestPermissionAndGetToken } from './firebaseConfig';
import Notifications from 'modules/letsMeet/utils/notification';
import { toast } from 'components/ui/toast/use-toast';
import { Button } from 'components/ui/button';
import SharePage from './modules/recieveEvent/SharePage';
import ReceivePublicEvent from './modules/letsMeet/pages/receivePublicEvent/receivePublicEvent';
import HomeRoutes from 'components/routes/HomeTree';
import { ReactComponent as GreenBell } from 'assets/images/event/greenBell.svg';
import { Toaster } from 'components/ui/toast/toaster';
import { IUser } from 'modules/auth/types/types';
import 'react-loading-skeleton/dist/skeleton.css'
import OneSignal from 'react-onesignal';
import { isTokensAvailable } from 'utils/authUtils';
import { publicRoutes } from 'modules/auth/constants/constants';
import { useValidateAuth } from 'hooks/useValidateAuth';
import { HelmetProvider } from 'react-helmet-async';
import { getDomainType } from 'utils/helpers';
import PrivacyPolicy from 'modules/auth/pages/privacy-policy/PrivacyPolicy';
import LandingPage from 'modules/landing/pages/MainPage';
import { isPWA } from 'utils/isPWA';
import { notificationService } from 'utils/notifications';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { useUpdateUserSettingsMutation } from 'services/slices/usersApiSlice';

function App() {
  const { loading, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [updateUser, { isLoading: updateUserIsLoading, data }] = useUpdateUserSettingsMutation();

  const initialized = useRef(false);
  // validate auth
  const {isValidAuth} = useValidateAuth();

  useEffect(() => {
    dispatch(whoAmIAction());
  }, [dispatch]);
  

  // useEffect(() => {
  //   requestPermissionAndGetToken();
  // }, []);

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/pushserviceworkers.js')
  //       .then((registration) => {
  //         console.log(
  //           'Service Worker registered with scope:',
  //           registration.scope,
  //         );
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }, []);

  const isPrivacyOrTOCPage = window.location.pathname.includes('privacy') || window.location.pathname.includes('terms');
  const domainType = getDomainType();
  const isRunningAsPWA = isPWA();

  console.log('isPWA', isRunningAsPWA);
  console.log
  useEffect(() => {
    if (!loading && user && user.availabilityTimezone) {
      if (
        new Intl.DateTimeFormat().resolvedOptions().timeZone !==
        user.availabilityTimezone
      ) {
        // toast({
        //   title:
        //     'Your Time zone has changed. Please update your availability settings to make the app functions smoothly',
        //   action: (
        //     <Button
        //       onClick={() => {}}
        //       className="text-sm text-white"
        //       variant="secondary"
        //     >
        //       <Link to="/settings/edit/availability">Update</Link>
        //     </Button>
        //   ),
        // });
      }
    }
  }, [loading]);

  // useEffect(() => {

  //      const notificationData = {
  //           type: NotificationType.POLL_VOTE,
  //           eventId: "66e1e5672a05ee5da9c33753" ,
  //           responder: "1212525215",
  //           response: 'No',
  //         };
  //   const testNotification = async () => {
  //     try {
  //       const response = await notificationService.sendNotification(
  //         notificationData
  //       );
  //       console.log('Notification test result:', response);
  //     } catch (error) {
  //       console.error('Notification test failed:', error);
  //     }
  //   };

  //   testNotification();
  // }, []); 
  
 useEffect(() => {
    if (!initialized.current) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID || '',
        allowLocalhostAsSecureOrigin: true
      });
      initialized.current = true;
    }

    if (user?._id) {
      OneSignal.login(user._id);
      OneSignal.Notifications.requestPermission();

      // set PWA mode if we're on it
      if (isRunningAsPWA) {
        updateUser({
          data: {
            usingPWAMode: true,
            
          } as any  // TOOD: FIX any typing...
        }).unwrap();
      }
    }
  }, [user]);


  if (domainType === 'landing' && !isRunningAsPWA && !isPrivacyOrTOCPage ) {
    return <LandingPage />;
  }

  // if (isRunningAsPWA && !isValidAuth) {
  //   return <Navigate to="/auth/start" replace />; 
  // }

  return (
    <HelmetProvider>
      <>
        <Notifications />
        <Routes>
          <Route path="/share/:id" element={<SharePage />} />
          <Route path="/public/:id" element={<ReceivePublicEvent />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="about" element={<About />} />
          <Route path="auth/*" element={<AuthRoutes />} />
          <Route
            path="auth/"
            element={
              <>
                <Navigate to="/auth/login" />
              </>
            }
          />
          <Route path="profile/*" element={<BaseTree />} />
          <Route
            path="profile/"
            element={
              <>
                <Navigate to="/profile/start" />
              </>
            }
          />
          <Route path="/" element={<HomeRoutes />} />
          <Route path="settings/*" element={<SettingsTree />} />
          <Route path="lets-meet/*" element={<LetsMeetRoutes />} />
          <Route path="activities/*" element={<ActivitiesRoutes />} />
          <Route path="home/*" element={<HomeRoutes />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </>
    </HelmetProvider>
  );
}

export default App;

