import { RootState } from 'app/store';
import axios from 'axios';
import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { IUser } from 'modules/auth/types/types';
import { IComment } from 'modules/letsMeet/components/comments/commentList';
import { ReceiveMeetResponseState, ReceiveMeetResponseType, ReceivePublicMeetResponseState } from 'modules/letsMeet/types/types';
import { Contact } from 'modules/profile/types/types';
import { useUpdateUserMutation } from 'services/slices/usersApiSlice';
import { twMerge } from 'tailwind-merge';
import { IEvent } from 'types';
import { isPWA } from './isPWA';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

class TrieNode {
  children: { [key: string]: TrieNode } = {};
  contacts: Contact[] = [];
}

export class Trie {
  root: TrieNode = new TrieNode();

  insert(contact: Contact) {
    let node = this.root;
    const nameLower = contact.name.toLowerCase();
    for (const char of nameLower) {
      if (!node.children[char]) {
        node.children[char] = new TrieNode();
      }
      node = node.children[char];
      node.contacts.push(contact);
    }
  }

  search(prefix: string): Contact[] {
    let node = this.root;
    for (const char of prefix.toLowerCase()) {
      if (!node.children[char]) {
        return [];
      }
      node = node.children[char];
    }
    return node.contacts;
  }

  clear() {
    this.root = new TrieNode();
  }
}

const sanitizePhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/\D/g, '');
};

export const addContactToTargetUser = async (targetUserId: string, contact: Omit<Contact, '_id'>) => {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = {
      'Authorization': `Bearer ${accessToken}`
    };

    const sanitizedContact = {
      ...contact,
      phoneNumber: `+${sanitizePhoneNumber(contact.phoneNumber)}`,
      name: contact.name.trim()
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/add-contacts`, 
      {
        userId: targetUserId,
        contacts: [sanitizedContact]
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error('Error adding contact:', error);
    throw error;
  }
};
export const getDomainType = () => {
  const hostname = window.location.hostname;
  const pathname = window.location.pathname;
  const isRunningPWA = isPWA();
  if (hostname === 'localhost') {
    // Show landing page if path starts with /landing
    if (pathname.startsWith('/landing')) {
      return 'landing';
    }
    // Otherwise show app
    return 'app';
  }
  // Main domains for landing page
  const mainDomains = [
    'caleido.social',
    'www.caleido.social',
    'staging.caleido.social',
    'dev.caleido.social'
  ];
  
  // App domains
  const appDomains = [
    'app.caleido.social',
    'app.staging.caleido.social',
    'app.dev.caleido.social'
  ];


  if (mainDomains.includes(hostname) && !isRunningPWA && pathname === '/') {
    return 'landing';
  } else if (appDomains.includes(hostname)) {
    return 'app';
  }
  
  // Local development
  return hostname === 'localhost' ? 'app' : 'unknown';
};
export const scrollToViewportIfNotIn = (
  element: HTMLElement | null,
  behavior?: ScrollBehavior,
) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();

  // NOTE: if the element is not in the viewport, scroll to it
  // if (rect.top < 0 || rect.bottom > window.innerHeight) {
  //   element.scrollIntoView({ behavior });
  //   return true;
  // }

  // NOTE: scroll based on offsetTop
  const offsetTop = element.offsetTop;
  window.scrollTo({ top: offsetTop+75, behavior });
  return true;    
};


// generate a hex number 24 characters long
export const getRandomHexID = () => {
  const length = 24;
  let result = '';
  const characters = '0123456789abcdef';
  for (let i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * characters.length)];
  }
  return result;
}



// check if the history is available to go back
export const canGoBack = () => {
  return window.history ? window.history.length > 1 : false;
};



export const getEventResponseNumber = (response?: ReceiveMeetResponseType) => {
  switch (response) {
    case ReceiveMeetResponseType.ACCEPTED:
      return 1;
    case ReceiveMeetResponseType.DECLINED:
      return 2;
    case ReceiveMeetResponseType.MAYBE:
      return 3;
    default:
      return 0;
  }
}

export const getResponseTypeFromState = (responseType:ReceiveMeetResponseType)=>{
  switch (responseType) {
    case ReceiveMeetResponseType.ACCEPTED:
      return ReceiveMeetResponseState.ACCEPTED;
    case ReceiveMeetResponseType.DECLINED:
      return ReceiveMeetResponseState.DECLINED;
    case ReceiveMeetResponseType.MAYBE:
      return ReceiveMeetResponseState.MAYBE;
    default:
      return ReceiveMeetResponseState.NOT_RESPONDED;
  }
}


export const getFormattedSharingText = (user:IUser, event:IEvent, link:string) => {  
  try{
    if(event.type==='poll'){        
        return `${capitalizeFirstLetter(user?.name.split(' ')[0] ?? '')} wants to find time to hang! Click to respond.`;
    }else if(event.type==='now'){
      return `${capitalizeFirstLetter(user?.name.split(' ')[0] ?? '')} wants to meet now for ${event?.activity?.trim()}. Click to respond and join!`;
    }else if (event.type==='later'){     
      return `${capitalizeFirstLetter(user?.name.split(' ')[0] ?? '')} wants to meet on ${format(new Date(event?.start!),"do 'of' MMMM")} for ${event?.activity}. Click to respond and join!`;
    }else{
      return `${capitalizeFirstLetter(user?.name.split(' ')[0] ?? '')} wants to meet on ${format(new Date(event?.start!),"do 'of' MMMM")} for ${event?.activity}. Click to respond and join!`;
    }
  }catch(e){
    return '';
  }
}

export const capitalizeFirstLetter = (word:string)=> {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}


export const getCommentsFromEvent = (event:IEvent):IComment[] => {
  if (event) {
    const newCommentsList = event.contacts?.flatMap(
        (contact: {
          comments?: any[];
          phoneNumber: any;
          name: any;
          status: any;
          organizer: any;
        }) => contact?.comments ? contact.comments?.map((comment) => ({
            eventId: event._id,
            id: comment._id,
            by: {
              phoneNumber: contact.phoneNumber,
              name: contact.name,
              status: contact.status,
              organizer: contact.organizer,
            },
            comment: comment.comment,
            timestamp: comment.timestamp,
          }))
          : [],
      )
      .sort(
        (
          a: { timestamp: string | number | Date },
          b: { timestamp: string | number | Date },
        ) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );
    return newCommentsList??[];
  }
  return [];
}

const getUserPhotosForEvent = async (eventId: string) => {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/users/event/${eventId}`,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching user photos:', error);
    return {};
  }
 };

export function getPWADisplayMode() {
  if (document.referrer.startsWith('android-app://'))
    return 'twa';
  if (window.matchMedia('(display-mode: browser)').matches)
    return 'browser';
  if (window.matchMedia('(display-mode: standalone)').matches)
    return 'standalone';
  if (window.matchMedia('(display-mode: minimal-ui)').matches)
    return 'minimal-ui';
  if (window.matchMedia('(display-mode: fullscreen)').matches)
    return 'fullscreen';
  if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
    return 'window-controls-overlay';

  return 'unknown';
}

